<template>
  <div id="app" class="flyout">
    <mdb-navbar v-show="navbarType == 'regular-fixed'" position="top"  dark color="indigo" name="Your Logo" href="#">
      <a href="https://agustinostv-front.archive.tornadocore.es" style="color: white;padding-right: 30px"><b>AGUSTINOS.TV</b></a>
      <mdb-navbar-toggler>
        <mdb-navbar-nav>
          <mdb-nav-item to="/home" waves-fixed>Novedades</mdb-nav-item>
          <mdb-nav-item to="/canales" waves-fixed>Canales</mdb-nav-item>
          <mdb-nav-item  v-if="user != null && (user.role == 'ROOT' || user.role == 'ADMIN' || user.role == 'SUPERVISOR')"to="/admin" waves-fixed>Admin</mdb-nav-item>

        </mdb-navbar-nav>

        <mdb-navbar-nav right>

          <li>
            <span  style="color: white;margin-right:7px;cursor: pointer" @click="IrPerfil" ref="username"></span>
          </li>
        </mdb-navbar-nav>  
      </mdb-navbar-toggler>

    </mdb-navbar>
    <main :style="{marginTop: '60px', marginBottom: '60px'}">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </main>
    <mdb-footer  dark color="indigo" style="position: fixed;width: 100%;">
      <p class="footer-copyright mb-0 py-3 text-center">
        &copy; {{new Date().getFullYear()}} Copyright:
        <a
          href="#"
        >AGUSTINOS.TV</a>
        | Versión 3.20.03.23
      </p>
    </mdb-footer>
  </div>
</template>

<script>
import {
  mdbNavbar,
  mdbNavItem,
  mdbNavbarNav,
  mdbNavbarToggler,
  mdbNavbarBrand,
  mdbFooter,
  mdbTooltip,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbInput,
  mdbBtn,  
} from "mdbvue"

import ApiService from '@/services/api';


export default {
  name: "PublicContainer",
  components: {
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbNavbarBrand, 
    mdbFooter,
    mdbTooltip,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbInput,
    mdbBtn,  
  },
  data() {
    return {
      navbarType: 'regular-fixed',
      content: false,
      username: '',
      user : null,
    };
  },
  created() {
    this.username = localStorage.getItem('username');
    window.document.title = "AGUSTINOS.TV";
  },
  mounted() {
    this.$refs.username.innerText = this.username;

    ApiService.getMe().then(r => {
    this.user = r.data;

    });
  },
  methods : {

    IrPerfil(e)
    {

        this.$router.push({ path: '/perfil'});
    },

  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}
.active {
  background-color: rgba(255, 255, 255, 0.1);
}
.demo-section {
  padding: 20px 0;
}
.demo-section > section {
  border: 1px solid #e0e0e0;
  padding: 15px;
}
.demo-section > h4 {
  font-weight: bold;
  margin-bottom: 20px;
}
.demo-title {
  color: #9e9e9e;
  font-weight: 700;
  margin-bottom: 0;
  padding-left: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

</style>
